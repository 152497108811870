ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  li {
    float: right;
  }
  
  li a {
    display: block;
    color: black;
    text-align: center;
    padding: 20px 20px;
    text-decoration: none;
    font-size: 1.15em;
  }

  li:nth-child(1) {
    float: left;
  }