.home {
    margin-left: 200px;
    margin-right: 200px;
    overflow: hidden;
}

.introduction {
    margin-top: 100px;
}

.introduction_content {
    font-style: italic;
    margin-bottom: 100px;
    padding: 0;
    font-size: 2em;
}

.header {
    font-size: 2em;
    font-weight: bold;
}

.skills_subheader {
    font-size: 1.25em;
    font-weight: bold;
    margin: 0;
    margin-top: 20px;
}

.skill_item {
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
}

.skill_item:hover {
    font-weight: bold;
}

.skill_name {
    display: inline;
    margin: 0;
}

.skill_score {
    float: right;
    display: inline;
    margin: 0;
}


.skills {
    width: 45%;
    display: inline-block;
    margin-right: 5%;
    margin-bottom: 100px;
}

.content {
    font-size: 1.2em;
    font-style: italic;
}

.column {
    width: 45%;
    margin-right: 5%;
    display: inline-block;
    padding-bottom: 100px;
    vertical-align: top;
}

.tag:nth-child(2n - 1) {
    transform: translateX(-120%);
    transition: transform 1.5s;
}

.tag:nth-child(2n) {
    transform: translateX(120%);
    transition: transform 1.5s;
}


.tag.visible {
    transform: translateX(0);
}

.hireme {
    padding-bottom: 200px;
}

.product {
    width: calc(25% - 20px);
    display: inline-block;
    vertical-align: top;
    margin: 10px;
}

.product_name {
    font-weight: bold;
    font-size: 1.2em;
}

.hireme_description {
    font-size: 1.1em;
}

.hoverable-word:hover {
    font-weight: 500;
}
@media all and (max-width:768px) {
    .home {
        margin-left: 20px;
        margin-right: 20px;
        overflow: hidden;
    }
    .product {
        width: calc(50% - 20px);
        display: inline-block;
        vertical-align: top;
        margin: 10px;
    }
    .thoughts {
        width: 100%;
        margin-right: 0;
        display: inline-block;
        padding-bottom: 100px;
        vertical-align: top;
    }
    
    .facts {
        width: 100%;
        margin-right: 0;
        display: inline-block;
        vertical-align: top;
        padding-bottom: 100px;
    }
    .skills {
        width: 100%;
        display: inline-block;
        margin-right: 0;
        margin-bottom: auto;
    }
    .skills:nth-child(2) {
        margin-bottom: 100px;
    }
}
@media all and (min-width:768px) and (max-width:1024px) {
    .home {
        margin-left: 100px;
        margin-right: 100px;
        overflow: hidden;
    }
}