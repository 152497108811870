.subheader {
    padding: none;
    margin: 0;
    opacity: 75%;
}
p.freelancer {
    margin-top: 0;
}
.no_link {
    text-decoration: none;
    color: black;
}
.connect {
    margin-bottom: 100px;
}